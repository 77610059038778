<template>
  <div class="w-full flex">
    <div class="w-full flex flex-col my-2">
      <div class="flex flex-grow flex-col">
        <span class="text-base font-semibold">  Upcoming Holidays </span>
        <span class="text-carrotOrange text-sm"> ({{ length }}) </span>
      </div>

      <div
        class="w-full flex my-6"
        v-for="(info, index) in holidays"
        :key="index"
      >
        <div class="flex">
                <Icon
                icon-name="icon-home"
                class="s mr-4"
                style="color: #321C3B; width: 50px; height: 50px;"
                />
        </div>

        <div class="w-full flex flex-col">
          <div class="text-base font-semibold">
            {{ `${info.holiday}` }}
          </div>
          <div class="text-sm mt-1">
            {{ `${info.calendar}` }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},

  props: {
    length: {
      type: String,
      default: "0"
    },

    holidays: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      password: ""
    };
  }

  /* mounted() {
        for(let i = 0; i < this.birthdays.length; i++) {
        const keyNames = Object.keys(this.birthdays[i]);
        console.log(keyNames[0]);
        }
    } */
};
</script>
